<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    dark
    temporary
    right
  >
    <v-list>
     <v-list-item
          v-for="link in links"
          :key="link.icon"
          link
          :to="link.to"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
          
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>الوضع المضلم</v-list-item-title>
           
          </v-list-item-content>
          <v-list-item-action> <v-switch
      v-model="isDark"
      
    ></v-switch></v-list-item-action>
        </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapMutations,
  } from 'vuex'

  export default {
    name: 'CoreDrawer',
     data: () => ({
   links: [
          { title: "الرئيسية", icon: "mdi-home", to: "/" },
          { title: "الفديوهات", icon: "mdi-video", to: "/videos" },
          { title: "المقالات", icon: "mdi-note-text", to: "/articles" },
          { title: "المنشورات", icon: "mdi-post", to: "/posts" },
          { title: "من نحن", icon: "mdi-information", to: "/#about" },
          { title: "اتصل بنا", icon: "mdi-phone", to: "/#contact" },
        ],
        isDark:false,
  }),
    computed: {
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },
    watch: {
      isDark(value){
        this.$vuetify.theme.dark = value;
      localStorage.setItem('theme', value ? 'dark' : 'light');
      }
     
    },
    methods: {
      ...mapMutations(['setDrawer']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to === '/') {
          this.$vuetify.goTo(0)
          this.setDrawer(false)
          return
        }

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href)
        this.setDrawer(false)
      },
      switchTheme(idDark) {
      this.$vuetify.theme.dark = idDark;
      localStorage.setItem('theme', idDark ? 'dark' : 'light');
    }
    },
  }
</script>
